<template>
  <div
    :class="{ 'has-error': validation.hasError('clause') }"
    class="form-group d-flex align-center"
  >
    <div class="col p-0">
      <textarea
        :value="clause"
        :class="{ 'has-error': validation.hasError('clause') }"
        rows="4"
        @input="inputClause"
        class="basic--input"
        :placeholder="$t('booking.clausePlaceholder')"
        name="clauses[]"
      >
      </textarea>
      <span class="val-error">{{ validation.firstError('clause') }}</span>
    </div>
    <div class="col-auto p-0" v-show="clauseLength > 1">
      <img src="@/assets/img/icons/trash.svg" @click="removeClause(index)" class="trash--button" />
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

export default {
  props: ['clause', 'index', 'clauseLength'],
  name: 'clause-field',
  validators: {
    clause: {
      validator(value) {
        return Validator.value(value)
          .minLength(10, this.$i18n.t('errors.clauses.min', { minChar: 10 }))
          .maxLength(400, this.$i18n.t('errors.clauses.max', { maxChar: 400 }));
      },
    },
  },
  methods: {
    inputClause($event) {
      this.$store.commit('v2/booking/acceptBookingRequest/INPUT_CLAUSE', {
        input: $event.target.value,
        index: this.index,
      });
    },
    removeClause(index) {
      this.$store.commit('v2/booking/acceptBookingRequest/REMOVE_CLAUSE', index);
    },
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/utils/variables';
.form-group {
  margin-left: 0;
  margin-right: 0;
}

@media #{$phones} {
  textarea {
    font-size: $font-xs;
  }
}
</style>
